<template>
  <div>
    <a-card title="分拣报告详情">
      <template slot="extra">
        <a-space>
          <a-button type="defualt" :loading="exportPDFLoading" @click="exportPDF">导出 PDF</a-button>
          <a-button type="defualt" :loading="exportExcelLoading" @click="exportExcel">导出 Excel</a-button>

          <a-divider type="vertical" />
          <a-button type="primary" icon="left" ghost @click="$router.go(-1)">返回</a-button>
        </a-space>
      </template>

      <a-spin :spinning="detailLoading">
        <a-descriptions bordered>
          <a-descriptions-item label="状态">
            {{ reportItem.status_display }}
          </a-descriptions-item>
          <a-descriptions-item label="创建人">
            {{ reportItem.creator_item?.name }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ reportItem.create_time }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>

      <a-row :gutter="[12, 12]" style="margin-top: 12px">
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="运单号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.audit_status" placeholder="审核状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="pending" value="pending">等待审核</a-select-option>
            <a-select-option key="approved" value="approved">通过</a-select-option>
            <a-select-option key="rejected" value="rejected">拒绝</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 280px">
          <a-button-group>
            <a-popconfirm title="确定批量通过吗" @confirm="batchApprove">
              <a-button type="primary" :loading="batchApproveLoading">批量通过</a-button>
            </a-popconfirm>
            <a-popconfirm title="确定批量拒绝吗" @confirm="batchReject">
              <a-button type="danger" :loading="batchRejectLoading">批量拒绝</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="packageItems"
            :loading="dataLoading"
            :pagination="pagination"
            :scroll="{ x: true }"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            @change="onTableChange"
          >
            <div slot="image" slot-scope="value, item" style="width: 36px; height: 36px">
              <div style="cursor: pointer">
                <img :src="value" preview="0" width="36" height="36" />
              </div>
            </div>
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @update="update" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import {
  sortingReportRetrieve,
  sortingReportBatchApprove,
  sortingReportBatchReject,
  sortingReportExportExcel,
  sortingReportExportPDF,
  sortingReportPackageList,
} from "@/apis/sorting";
import { exportExcel, exportPDF } from "@/utils/excel";
import { replaceItem } from "@/utils/functions";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "图片",
          dataIndex: "image",
          width: 60,
          scopedSlots: { customRender: "image" },
        },
        {
          title: "运单号",
          dataIndex: "tracking_number",
          width: 160,
        },
        {
          title: "分拣状态",
          dataIndex: "sorting_status_display",
          width: 100,
        },
        {
          title: "分拣时间",
          dataIndex: "sorting_time",
          width: 180,
        },
        {
          title: "重量(kg)",
          dataIndex: "weight",
          width: 100,
          customRender: (value) => (value ? value : 0),
        },
        {
          title: "长(cm)",
          dataIndex: "length",
          width: 100,
          customRender: (value) => (value ? Math.round(value / 10) : 0),
        },
        {
          title: "宽(cm)",
          dataIndex: "width",
          width: 100,
          customRender: (value) => (value ? Math.round(value / 10) : 0),
        },
        {
          title: "高(cm)",
          dataIndex: "height",
          width: 100,
          customRender: (value) => (value ? Math.round(value / 10) : 0),
        },
        {
          title: "体积重(kg)",
          dataIndex: "volume",
          width: 100,
          customRender: (_, item) => {
            const length = item.length ? Math.round(item.length / 10) : 0;
            const width = item.width ? Math.round(item.width / 10) : 0;
            const height = item.height ? Math.round(item.height / 10) : 0;
            return ((length * width * height) / 5000).toFixed(2);
          },
        },
        {
          title: "审核状态",
          dataIndex: "audit_status_display",
          width: 100,
        },
        {
          title: "审核时间",
          dataIndex: "audit_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      reportItem: {},
      packageItems: [],

      sortingReportId: null,
      detailLoading: false,
      dataLoading: false,

      batchApproveLoading: false,
      batchRejectLoading: false,
      exportPDFLoading: false,
      exportExcelLoading: false,
      selectedRowKeys: [],
    };
  },
  methods: {
    initialData() {
      this.sortingReportId = this.$route.query.id;
      this.detailLoading = true;
      sortingReportRetrieve({ id: this.sortingReportId })
        .then((data) => {
          this.reportItem = data;
        })
        .finally(() => {
          this.detailLoading = false;
        });

      this.list();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      sortingReportPackageList({ sorting_report: this.sortingReportId, ...this.searchForm })
        .then((data) => {
          this.pagination.total = data.count;
          this.packageItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    onTableChange(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    update(item) {
      this.packageItems = replaceItem(this.packageItems, item);
    },
    batchApprove() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请选择运单号");
        return;
      }

      this.batchApproveLoading = true;
      sortingReportBatchApprove({ id: this.sortingReportId, sorting_report_package_set: this.selectedRowKeys })
        .then((data) => {
          this.$message.success("通过成功");
          this.selectedRowKeys = [];
          for (const item of data) {
            this.update(item);
          }
        })
        .finally(() => {
          this.batchApproveLoading = false;
        });
    },
    batchReject() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请选择运单号");
        return;
      }

      this.batchRejectLoading = true;
      sortingReportBatchReject({ id: this.sortingReportId, sorting_report_package_set: this.selectedRowKeys })
        .then((data) => {
          this.$message.success("拒绝成功");
          this.selectedRowKeys = [];
          for (const item of data) {
            this.update(item);
          }
        })
        .finally(() => {
          this.batchRejectLoading = false;
        });
    },
    exportPDF() {
      this.exportPDFLoading = true;
      sortingReportExportPDF({ id: this.sortingReportId })
        .then((response) => {
          exportPDF(response, "分拣包裹");
        })
        .finally(() => {
          this.exportPDFLoading = false;
        });
    },
    exportExcel() {
      this.exportExcelLoading = true;
      sortingReportExportExcel({ id: this.sortingReportId })
        .then((response) => {
          exportExcel(response, "分拣包裹");
        })
        .finally(() => {
          this.exportExcelLoading = false;
        });
    },
  },
  mounted() {
    this.initialData();
  },
};
</script>

<style scoped></style>
